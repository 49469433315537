import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checktoken, getteam } from "../../API/API";
import {
  Close,
  Eye,
  MenuDots,
  Pencil,
  Plus,
  Setting,
  Trash,
} from "../../Component/Common/Images/Images";
import { SearchAndFilterBar } from "../../Component/Common/SearchAndFilterBar/SearchAndFilterBar";
import { Table } from "../../Component/Common/Table/Table";
import { AddteamModal } from "../../Component/Team/AddteamModal/AddteamModal";
import { DeleteTeam } from "../../Component/Team/DeleteTeam/DeleteTeam";
import { EditteamModal } from "../../Component/Team/EditteamModal copy/EditteamModal";
import { ResetPasswordModal } from "../../Component/Team/ResetPasswordModal/ResetPasswordModal";
import { ViewteamModal } from "../../Component/Team/ViewteamModal/ViewteamModal";

import "./teamStyle.scss";
import {
  ExportAll,
  TeamCsv,
} from "../../Component/TableCsvConverter/TableToCsv";

const team_init = [
  {
    id: "1",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "2",
    name: "Jim Newton",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "3",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "4",
    name: "Jim Newton",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "5",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "6",
    name: "Jim Newton",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "7",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "8",
    name: "Jim Newton",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "9",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "10",
    name: "Jim Newton",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "11",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "12",
    id: "12",
    name: "Jim Newton",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "13",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "14",
    name: "Jim Newton",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "15",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "16",
    name: "Jim Newton",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "17",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "18",
    name: "Jim Newton",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
  {
    id: "19",
    name: "Ken Kent",
    mobile: "6465256754",
    email: "kenJuliette123@mail.com",
    password: "9",
  },
];

export const Team = ({ setloader, setshow_navbar }) => {
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [team_list, setteam_list] = useState([]);
  const [addteammodal, setaddteammodal] = useState(false);
  const [viewteammodal, setviewteammodal] = useState(false);
  const [editteammodal, seteditteammodal] = useState(false);
  const [resetpasswordmodal, setresetpasswordmmodal] = useState(false);
  const [deleteteammodal, setdeleteteammodal] = useState(false);
  const [search, setsearch] = useState("");
  const [teammenu, setteammenu] = useState(false);
  const [current_employee, setcurrent_employee] = useState({});

  useEffect(() => {
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("jc_pos_token");
      if (local_token) {
        checktoken(local_token, (data) => {
          if (data.status) {
            dispatch({
              type: "login",
              payload: { token: local_token, admin: data.admin },
            });
            return navigate("/team");
          }
        });
      } else {
      }
    } else {
      if (current_store.user.admin === true) {
        setloader(true);
        getteam({}, current_store.user.token, (data) => {
          if (!data.status) {
            return navigate("/");
          }
          setteam_list([
            ...data.data.employees.map((el) => {
              return {
                id: el.id,
                name: el.name,
                // mobile: el?.mobile ? el.mobile : "-",
                email: el.email,
                password: Math.floor(Math.random() * 2) + 6,
                admin: el.admin,
              };
            }),
          ]);
          setloader(false);
        });
      } else {
        return navigate("/404");
      }
    }
  }, [current_store.user.token]);
  useEffect(() => {
    setshow_navbar(true);
  }, []);
  return (
    <div className="team_page">
      <div
        className="pagetitle"
        // onClick={() => {
        //   setshow_deleted_items(!show_deleted_items);
        // }}
      >
        <div>
          Team
          <div
            className="orders_download"
            onClick={() => {
              ExportAll(
                [
                  ...team_list.filter(
                    (el) =>
                      el.name.toLowerCase().match(search.toLowerCase()) ||
                      el.email.toLowerCase().match(search.toLowerCase())
                  ),
                ].map((el2) =>
                  TeamCsv({
                    data: {
                      ...el2,
                    },
                  })
                ),
                "Team"
              );
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M11.5425 9.23571L18.8885 1.88965"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.6053 5.47397V1.17383H15.3052"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.75099 1.17383H7.95927C3.47996 1.17383 1.68823 2.96555 1.68823 7.44486V12.82C1.68823 17.2993 3.47996 19.0911 7.95927 19.0911H13.3344C17.8137 19.0911 19.6055 17.2993 19.6055 12.82V11.0283"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        {
          // type == "report" ? (
          //   ""
          // ) : (
          // )
        }
      </div>
      <div className="team_body">
        <div className="team_search_and_add">
          <SearchAndFilterBar
            filter_show={"no"}
            placeholder={"Search for Name, Mail,..."}
            handlechange_search={(data) => {
              setsearch(data);
            }}
          />
          <div
            className="add_employee"
            onClick={() => {
              setaddteammodal(true);
            }}
          >
            <Plus white={true} custStyle={{ height: 25, width: 25 }} />
            Employee
          </div>
        </div>
        <Table
          cust_class={"team_table"}
          type="type1"
          table_head_data={[
            "NAME",
            //  "MOBILE",
            "MAIL",
            "PASSWORD",
            "",
          ]}
          table_body_data={[
            ...team_list
              .filter(
                (el) =>
                  el.name.toLowerCase().match(search.toLowerCase()) ||
                  el.email.toLowerCase().match(search.toLowerCase())
              )
              .map((el, i) => [
                // el.id,
                el.name,
                // el.mobile,
                el.email,
                new Array(Number(el.password)).fill("*").join(""),
                <div className="team_menu">
                  <MenuDots clickhandel={() => setteammenu(`team_menu_${i}`)} />
                  {teammenu == `team_menu_${i}` && (
                    <div className="team_menu_main">
                      <div className="team_menu_header">
                        <Close type="sm" clickhandel={() => setteammenu("")} />
                        {/* <Close /> */}
                      </div>
                      <div className="team_menu_body">
                        <div
                          onClick={() => {
                            setcurrent_employee({ ...el });
                            setviewteammodal(true);
                            setteammenu(``);
                          }}
                        >
                          <Eye /> View
                        </div>
                        <div
                          onClick={() => {
                            setcurrent_employee({ ...el });
                            seteditteammodal(true);
                            setteammenu(``);
                          }}
                        >
                          <Pencil edit={true} /> Edit
                        </div>
                        <div
                          onClick={() => {
                            setcurrent_employee({ ...el });
                            setresetpasswordmmodal(true);
                            setteammenu(``);
                          }}
                        >
                          <Setting /> Reset Password
                        </div>
                        <div
                          onClick={() => {
                            setcurrent_employee({ ...el });
                            setdeleteteammodal(true);
                            setteammenu(``);
                          }}
                        >
                          <Trash /> Delete
                        </div>
                      </div>
                      <div className="team_menu_footer"></div>
                    </div>
                  )}
                </div>,
              ]),
          ]}
        />
      </div>
      {addteammodal && (
        <AddteamModal
          setteam_list={setteam_list}
          setloader={setloader}
          close={() => {
            setaddteammodal(false);
          }}
        />
      )}
      {editteammodal && (
        <EditteamModal
          setteam_list={setteam_list}
          setloader={setloader}
          current_data={current_employee}
          close={() => {
            seteditteammodal(false);
          }}
        />
      )}
      {viewteammodal && (
        <ViewteamModal
          current_data={current_employee}
          close={() => {
            setviewteammodal(false);
          }}
        />
      )}
      {resetpasswordmodal && (
        <ResetPasswordModal
          setteam_list={setteam_list}
          setloader={setloader}
          current_data={current_employee}
          close={() => {
            setresetpasswordmmodal(false);
          }}
        />
      )}
      {deleteteammodal && (
        <DeleteTeam
          setteam_list={setteam_list}
          setloader={setloader}
          current_data={current_employee}
          close={() => {
            setdeleteteammodal(false);
          }}
        />
      )}
    </div>
  );
};
