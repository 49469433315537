import React, { useEffect, useState } from "react";
import "./addorderstatusStyle.scss";

import { add_order_status } from "../../../Component/dummydata";

import { ChargeOrderModal } from "./ChargeOrderModal/ChargeOrderModal";
import { convert_to_am_pm } from "../../../API/API";
import { TempDeliveryModal } from "../../../Pages/TempDraftOrdersTable/TempDeliveryModal/TempDeliveryModal";
import { logDOM } from "@testing-library/react";

const order_statuses = [
  // "All",
  "Pickup Requested",
  "Driver Enroute to Pickup",
  "Picked Up",
  "Customer Charged",
  "Deliver Clothes",
  "Driver Enroute to Dropoff",
  // "Order Cancelled",
  "Order Completed",
];

export const AddOrderStatus = ({
  data,
  total,
  payment_details,
  setpayment_details,
  setloader,
}) => {
  const [payment_modal, setpayment_modal] = useState(false);
  const [charge_deliver_modal, setcharge_deliver_modal] = useState(false);
  const [complete_deliver_modal, setcomplete_deliver_modal] = useState(false);
  const [delivery_modal, setdelivery_modal] = useState(false);
  const [filtered_status, setfiltered_status] = useState({});
  const [last_status, setlast_status] = useState(-1);
  const setstatuses = (arr) => {
    // console.log("setstatuses function hit");
    let last_status_index = -1;
    let temp_obj = {};
    arr.map((el) => {
      let { stage, created_at } = el;
      let name = stage == "Order Charged" ? "Customer Charged" : stage;
      if (!temp_obj[name]) {
        temp_obj[name] = created_at;
        let index = order_statuses.indexOf(name);
        if (index > last_status_index) {
          last_status_index = index;
        }
      }
    });
    setlast_status(last_status_index);
    setfiltered_status({ ...temp_obj });
    // console.log(temp_obj);
  };
  useEffect(() => {
    let temp_status = [...data.status_order_data];
    setstatuses(temp_status);
  }, []);
  // console.log(data);
  return (
    <div className="addorderstatus">
      {data.status_order_data && (
        <div className="left_side">
          {order_statuses.map((el, i) => {
            if (
              (!data.address || data.address == "no, no, no") &&
              ["Deliver Clothes", "Driver Enroute to Dropoff"].indexOf(el) != -1
            ) {
              return;
            }
            if (
              (el == "Pickup Requested" &&
                !filtered_status["Pickup Requested"]) ||
              (el == "Driver Enroute to Pickup" &&
                !filtered_status["Pickup Requested"])
            ) {
              return;
            }

            return (
              <div className="status_tabs">
                <div
                  className="status_orbs"
                  title={i + "_" + last_status}
                  state={
                    i < last_status ? "0" : i == last_status ? "1" : "none"
                  }
                ></div>
                <div className="status_type">
                  <div>{el}</div>
                  <div className="status_time">
                    {filtered_status[el]
                      ? convert_to_am_pm(filtered_status[el])
                      : i < last_status && "no data"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="status_btn_group">
        {/* <div>{payment_details.payed.toString()}</div> */}
        {/* <div>{data.charged.toString()}</div> */}
        {payment_details.payed || data.charged == "1" ? (
          // && false
          <>
            {(data.delivered == "0" ||
              data.file_data.payment_details.payed_as.delivery == "0") && (
              <>
                {data.address && data.address != "no, no, no" && (
                  <div
                    className="btn"
                    type="type2"
                    onClick={() => setdelivery_modal(true)}
                  >
                    Start Delivery
                  </div>
                )}
              </>
            )}
            <div
              className="btn"
              type="type2"
              onClick={() => setcomplete_deliver_modal(true)}
            >
              Mark as completed
            </div>
          </>
        ) : (
          <>
            <div
              className="btn"
              type="type2"
              onClick={() => setpayment_modal(true)}
            >
              Charge Order
            </div>
            {data.address && data.address != "no, no, no" && (
              <div
                className="btn"
                type="type2"
                onClick={() => setcharge_deliver_modal(true)}
              >
                Charge & Deliver
              </div>
            )}
          </>
        )}
        {data.charged == "1" && (
          <div
            className="btn"
            type="type2"
            onClick={() => {
              const axios = require("axios");
              let data = JSON.stringify({
                id: "1572",
              });

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://api.juliettecleaners.com/api/pos/v1/get-order-by-id",
                headers: {
                  Accept: "application/json, text/plain, */*",
                  "Accept-Language": "en-US,en;q=0.9",
                  Connection: "keep-alive",
                  "Content-Type": "application/json",
                  Origin: "https://admin.juliettecleaners.com",
                  Referer: "https://admin.juliettecleaners.com/receipt/1572",
                  "Sec-Fetch-Dest": "empty",
                  "Sec-Fetch-Mode": "cors",
                  "Sec-Fetch-Site": "same-site",
                  "User-Agent":
                    "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
                  "sec-ch-ua":
                    '"Google Chrome";v="125", "Chromium";v="125", "Not.A/Brand";v="24"',
                  "sec-ch-ua-mobile": "?0",
                  "sec-ch-ua-platform": '"Windows"',
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {
                  console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            Send Receipt
          </div>
        )}
        {/* <div
          className="btn"
          type="type2"
          onClick={() => {
            // settag_ids([...tag_ids.filter(el => (el.order_id != '' && el.order_item != 'Select One...') && el)]);
            // // settag_ids([...tag_ids.filter=>])
            // setadd_order_current_showing("add_order_menu_images");
          }}
        >
          Print Ticket
        </div> */}
      </div>
      {(payment_modal || charge_deliver_modal) && (
        <ChargeOrderModal
          delivery={charge_deliver_modal}
          data={data}
          setpayment_modal={
            charge_deliver_modal ? setcharge_deliver_modal : setpayment_modal
          }
          total={total}
          payment_details={payment_details}
          setpayment_details={setpayment_details}
          setloader={setloader}
          setstatuses={setstatuses}
        />
      )}
      {(delivery_modal || complete_deliver_modal) && (
        <TempDeliveryModal
          delivery={delivery_modal}
          data={data}
          cancel={() =>
            complete_deliver_modal
              ? setcomplete_deliver_modal(false)
              : setdelivery_modal(false)
          }
          setloader={setloader}
          setstatuses={setstatuses}
        />
      )}
    </div>
  );
};
