import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkpasssword } from "../../../API/API";
import { base_url } from "../../../API/data";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { Close, Eye } from "../../Common/Images/Images";
import { Preview } from "../../FAQ/Preview/Preview";
import "./checkpasswordStyle.scss";
export const CheckPassword = ({ close, btn_name, btn_cb, setloader }) => {
  const [values, setvalues] = useState({
    // mail: "punitesh@siti-solution.com",
    pass1: "temporary@12345",
    pass2: "temporary@12345",
    // pass2: "",
    // pass1: "",
    show_pass1: false,
    show_pass2: false,
    error: "",
  });
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const check_password = () => {
    setloader(true);
    // axios
    //   .post(`${base_url}/api/pos/v1/login`, {
    //     email: values.mail,
    //     password: encrypt_data(values.pass1),
    //   })
    //   .then((res) => res.data)
    //   .then((res) => {
    //     if (res.error) {
    //       if (
    //         ["Invalid Credentials", "User does not exist"].indexOf(
    //           res.error
    //         ) !== -1
    //       ) {
    //         setloader(false);
    //         setvalues((prev) => ({ ...prev, error: res.error }));
    //         return;
    //       }
    //       navigate("/404");
    //       return;
    //     }
    //     btn_cb();
    //     close();
    //     setloader(false);
    //   });
    checkpasssword(
      { password: encrypt_data(values.pass1) },
      current_store.user.token,
      (data) => {
        if (!data.status) {
          return navigate("/");
        }
        btn_cb();
        close();
        setloader(false);
      }
    );
  };
  return (
    <div className="checkpassword">
      <div>
        <div className="header">
          <Close clickhandel={() => close()} />
        </div>
        <div className="body">
          {/* <div className="title">Enter Email</div>
          <div className="cust_inp_password">
            <input
              style={{ width: "100%" }}
              type={"text"}
              value={values.mail}
              onChange={({ target }) => {
                setvalues((prev) => ({
                  ...prev,
                  mail: target.value,
                  error: "",
                }));
              }}
            />
          </div> */}
          <div className="title">Enter Passsword</div>
          <div className="cust_inp_password">
            <input
              type={values.show_pass1 ? "text" : "password"}
              value={values.pass1}
              onChange={({ target }) => {
                setvalues((prev) => ({ ...prev, pass1: target.value }));
              }}
            />
            <Eye
              clickhandel={() =>
                setvalues((prev) => ({
                  ...prev,
                  show_pass1: !prev.show_pass1,
                  error: "",
                }))
              }
            />
          </div>
          <div className="title">Confirm Passsword</div>
          <div className="cust_inp_password">
            <input
              type={values.show_pass2 ? "text" : "password"}
              value={values.pass2}
              onChange={({ target }) => {
                setvalues((prev) => ({ ...prev, pass2: target.value }));
              }}
            />
            <Eye
              clickhandel={() =>
                setvalues((prev) => ({ ...prev, show_pass2: !prev.show_pass2 }))
              }
            />
          </div>
          {values.error && <div>{values.error}</div>}
        </div>
        <div className="footer">
          <div
            className="btn"
            type={
              values.pass1 === values.pass2 &&
              values.pass1 !== "" &&
              // values.mail !== "" &&
              values.pass2 !== ""
                ? "type2"
                : "type1"
            }
            onClick={() => {
              if (
                values.pass1 === values.pass2 &&
                values.pass1 !== "" &&
                // values.mail !== "" &&
                values.pass2 !== ""
              ) {
                check_password();
              }
            }}
          >
            Submit & {btn_name}
          </div>
        </div>
      </div>
    </div>
  );
};
