import React, { useState } from "react";
import { Close } from "../../../Component/Common/Images/Images";
import "./tempdeliverymodalStyle.scss";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { base_url } from "../../../API/data";
import { complete_order } from "../../../API/API";
import { useNavigate } from "react-router-dom";

const run_api = !false;

export const TempDeliveryModal = ({ cancel, data, delivery }) => {
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();
  return (
    <div
      className="tempcancelmodal"
      id="close_modal"
      onClick={(e) => {
        e.target.id == "close_modal" && cancel();
      }}
    >
      <div>
        <div className="body">
          <div>
            Are you sure
            {delivery ? (
              <>
                <br /> You want to <strong>deliver</strong> this order?
              </>
            ) : (
              <>
                <br /> You want to <strong>complete</strong> this order?
              </>
            )}
          </div>
          <div
            className="btn"
            type="type2"
            onClick={() => {
              let completeorder_obj = {
                order_id: data.order_id,
                client_id: data.client_id,
                create_onfleet: delivery,
              };
              if (!run_api) {
                return;
              }
              complete_order(
                { ...completeorder_obj },
                current_store.user.token,
                (data) => {
                  if (!data.status) {
                    return navigate("/");
                  }
                  return navigate("/orders");
                }
              );
              // axios
              //   .post(
              //     `${base_url}/api/pos/v1/start-delivery`,
              //     // 'https://791f-182-48-204-207.in.ngrok.io/api/pos/v1/start-delivery'
              //     {
              //       order_id: data.order_id,
              //       client_id: data.client_id,
              //     },
              //     {
              //       headers: {
              //         Authorization: current_store.user.token,
              //       },
              //     }
              //   )
              //   .then((res) => res.data)
              //   .then((res) => {
              //     if (res.error) {
              //       // setapi_error(res.error)
              //       return;
              //     }
              //     setTimeout(() => {
              //       window.location.reload();
              //     }, 100);
              //     cancel();
              //   });
            }}
          >
            Confirm
          </div>
          <div className="btn" type="type2" onClick={cancel}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};
