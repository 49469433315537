import React, { useState } from "react";
import { Close } from "../../../Component/Common/Images/Images";
import "./tempdraftmodalStyle.scss";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { base_url } from "../../../API/data";

export const TempDraftModal = ({ cancel, order_selected }) => {
  const current_store = useSelector((state) => state);
  const [amount, setamount] = useState("");
  const [delivered, setdelivered] = useState("");
  const [charge_api_loading, setcharge_api_loading] = useState(false);
  const [api_error, setapi_error] = useState();
  return (
    <div
      className="tempdraftmodal"
      id="close_modal"
      onClick={(e) => {
        e.target.id == "close_modal" && cancel();
      }}
    >
      <div>
        <div className="header">
          <Close clickhandel={cancel} />
        </div>
        <div className="body">
          {/* <div className='margin_top_auto'></div> */}
          <div className="cust_input">
            <input
              type="number"
              placeholder="Amount"
              value={amount}
              onChange={(e) => {
                setamount(e.target.value);
                setapi_error("");
              }}
            />
            <div>$</div>
          </div>
          {/* <div className='margin_top_auto'></div> */}
          <div className="cust_input cust_delivered">
            <input
              type="checkbox"
              id="delievered_checkbox"
              checked={delivered}
              onChange={(e) => {
                setdelivered(e.target.checked);
                setapi_error("");
              }}
            />
            <label htmlFor="delievered_checkbox">Deliver</label>
          </div>
          {api_error && <div>Error : {api_error}</div>}
          {/* <div className='margin_top_auto'></div> */}
        </div>
        <div
          className="btn"
          type={amount == "" || api_error ? "type1" : "type2"}
          onClick={() => {
            if (amount == "" || charge_api_loading) {
              return;
            }
            setcharge_api_loading(true);
            axios
              .post(
                `${base_url}/api/pos/v1/charge-order`,
                // 'https://791f-182-48-204-207.in.ngrok.io/api/pos/v1/charge-order'
                {
                  order_id: order_selected.order_id,
                  amount: amount,
                  delivery: delivered ? "1" : "0",
                  client_id: order_selected.client_id,
                },
                {
                  headers: {
                    Authorization: current_store.user.token,
                  },
                }
              )
              .then((res) => res.data)
              .then((res) => {
                if (res.error) {
                  setapi_error(res.error);
                  setcharge_api_loading(false);
                  return;
                }
                localStorage.setItem("charged order", JSON.stringify(res));
                setcharge_api_loading(false);
                window.location.reload();
                cancel();
              });
          }}
        >
          {charge_api_loading ? "Charging..." : "Charge Order"}
        </div>
      </div>
    </div>
  );
};
