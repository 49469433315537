import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checktoken } from "../../API/API";
import "./settingsStyle.scss";
import { PrinterSettings } from "../../Component/Settings/PrinterSettings";

export const Settings = ({ setshow_navbar, setloader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_store = useSelector((state) => state);
  const [current_selected, setCurrent_selected] = useState("printer_settings");
  useEffect(() => {
    setshow_navbar(true);
  }, []);
  useEffect(() => {
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("jc_pos_token");
      if (local_token) {
        checktoken(local_token, (data) => {
          if (data.status) {
            dispatch({
              type: "login",
              payload: { token: local_token, admin: data.admin },
            });
            return navigate("/settings");
          }
        });
      } else {
      }
    } else {
      if (current_store.user.admin === true) {
        return;
      } else {
        return navigate("/404");
      }
    }
  }, [current_store.user.token]);
  return (
    <div className="settings_main">
      <div className="pagetitle">
        <div>Settings</div>
      </div>
      <div className="page_divider">
        {/* <div className="page_nav">
          <div
            className={
              "printer_settings" +
              (current_selected == "printer_settings" ? " active" : "")
            }
            onClick={() => setCurrent_selected("printer_settings")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7.25 7H16.75V5C16.75 3 16 2 13.75 2H10.25C8 2 7.25 3 7.25 5V7Z"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 15V19C16 21 15 22 13 22H11C9 22 8 21 8 19V15H16Z"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 10V15C21 17 20 18 18 18H16V15H8V18H6C4 18 3 17 3 15V10C3 8 4 7 6 7H18C20 7 21 8 21 10Z"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17 15H15.79H7"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 11H10"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="txt">Printer</div>
          </div>
        </div> */}
        {/* <div className="page_view"> */}
        <PrinterSettings />
        {/* </div> */}
      </div>
    </div>
  );
};
