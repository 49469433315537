import React from "react";
import { convert_cashdate, convert_cashtime } from "../../Pages/Cash/Cash";
import { mobile_convert_display } from "../../API/API";

export function ExportAll(arrayData, filename) {
  // console.log(data1);

  // console.log(arrayData, "------------------------");

  let Que = arrayData[0]
    ?.filter((datafil) => !datafil.not_csv)
    ?.map((el) => el.Question);
  let Ans = arrayData?.map((el) =>
    el?.filter((datafil) => !datafil.not_csv)?.map((el1) => el1.Ans)
  );
  let rows = [Que, ...Ans];
  let csvContent = "";
  rows?.forEach((row) => {
    const rowString = row?.map((cell) => `"${cell}"`)?.join(",");
    csvContent += rowString + "\n";
  });

  if(Ans.length === 0){
    return
  }
  // Create a Blob from the CSV string
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a temporary link element to download the file
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  // Append the link to the document and click it to trigger the download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // return console.log(Que, Ans);
}

export let ClientCsv = ({ data }) => [
  {
    Question: "PROFILE PIC",
    Ans: data.img || "-",
  },
  {
    Question: "NAME",
    Ans: data.name || "-",
  },
  {
    Question: "MOBILE",
    Ans: mobile_convert_display(data.phone) || "-",
  },
  {
    Question: "EMAIL",
    Ans: data.email || "-",
  },
  {
    Question: "CC ON FILE",
    Ans: data.payment != "" ? `**** **** ${data.payment}` : "-" || "-",
  },
  {
    Question: "SIGNUP DATE",
    Ans: data.created_at || "-",
  },
];

export let TeamCsv = ({ data }) => [
  {
    Question: "NAME",
    Ans: data.name || "",
  },
  {
    Question: "EMAIL ID",
    Ans: data.email || "",
  },
];
export let OrdersCsv = ({ data }) => [
  {
    Question: "ID",
    Ans: data.order_id,
  },
  {
    Question: "NAME",
    Ans: data.client_name || "",
  },
  {
    Question: "COST",
    Ans: data.amount || "-",
  },
  {
    Question: "STATUS",
    Ans: data.display_status || "",
  },
  {
    Question: "ONFLEET STATUS",
    Ans: data.shortId || "-",
  },
  {
    Question: "CREATED ON",
    Ans: data.order_date + " " + data.order_time || "-",
  },
];

export let DiscountCsv = ({ data }) => [
  {
    Question: "ACTIVE PROMOTION",
    Ans: data.discount_txt || "",
  },
  {
    Question: "DESCRIPTION",
    Ans: data.desc || "",
  },
  {
    Question: "ACTION",
    Ans: data.is_active ? data.is_active : "FALSE" || "",
  },
];
export let CashCsv = ({ data }) => [
  {
    Question: "NAME",
    Ans: data.full_name || "",
  },
  {
    Question: "AMOUNT",
    Ans:
      Number(data.amount) >= 0
        ? `+ $ ${Number(data.amount).toFixed(2)}`
        : `- $ ${Math.abs(Number(data.amount)).toFixed(2)}` || "",
  },
  {
    Question: "DATE",
    Ans:
      new Date(data.dateTime) != "Invalid Date"
        ? convert_cashdate(data.dateTime)
        : data.dateTime || "",
  },
  {
    Question: "TIME",
    Ans:
      new Date(data.dateTime) != "Invalid Date"
        ? convert_cashtime(data.dateTime)
        : data.dateTime || "",
  },
  {
    Question: "DESCRIPTION",
    Ans: data.description || "",
  },
];

export let VendorsCsv = ({ data }) => [
  {
    Question: "NAME",
    Ans: data.name || "",
  },
  {
    Question: "MOBILE",
    Ans: mobile_convert_display(data.mobile) || "",
  },
  {
    Question: "NOTE",
    Ans: data.note || "",
  },
];
export let ReportsCsv = ({ data }) => [
  {
    Question: "NAME",
    Ans: data.client_name || "",
  },
  {
    Question: "Order created",
    Ans: data.order_date + " " + data.order_time || "",
  },
  {
    Question: "Payment status",
    Ans: data.amount ? "Paid" : "Un Paid" || "",
  },
  {
    Question: "Status",
    Ans: data.display_status || "",
  },
  {
    Question: "Order number",
    Ans: data.order_id || "",
  },
];
