import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSlate } from "slate-react";
// import { Close } from "../Common/Images/Images";

export const PrinterSettings = () => {
  const current_store = useSelector((state) => state);
  const [inp, setinp] = useState("");
  const [printer_ip, setprinter_ip] = useState("");

  const dispatch = useDispatch();
  console.log(current_store);

  const print_Data = () => {
    console.log("started");

    var printer = null;

    var ePosDev = new window.epson.ePOSDevice();

    ePosDev.connect(printer_ip, 8008, cbConnect);

    function cbConnect(data) {
      console.log("started connection");
      if (data == "OK" || data == "SSL_CONNECT_OK") {
        ePosDev.createDevice(
          "local_printer",
          ePosDev.DEVICE_TYPE_PRINTER,
          { crypto: false, buffer: false },
          cbCreateDevice_printer
        );
      } else {
        console.log(data);
      }
    }

    function cbCreateDevice_printer(devobj, retcode) {
      console.log("checking connection");
      if (retcode == "OK") {
        printer = devobj;
        printer.timeout = 60000;
        printer.onreceive = function (res) {
          console.log(res);
          console.log(res.success);
        };
        printer.oncoveropen = function () {
          console.log("coveropen");
        };
        console.log(printer);
        console.log("started printing");
        print();
      } else {
        console.log(retcode);
      }
    }

    function print() {
      printer.addLayout(printer.LAYOUT_RECEIPT, 800, 0, 0, 0, 0, 0);
      printer.addTextLineSpace(20);
      printer.addText("\n");
      printer.addTextSmooth(true);
      printer.addTextStyle(false, false, true, printer.COLOR_1);
      printer.addText("Test Complete\n");
      printer.addTextLineSpace(100);
      printer.addText("\n");
      printer.addCut(printer.CUT_FEED);
      printer.send();
    }
  };
  useEffect(() => {
    let local_token = localStorage.getItem("jc_printer_ip");
    setinp(local_token);
    setprinter_ip(local_token);
    dispatch({
      type: "put_default",
      payload: { printer_ip: local_token },
    });
  }, []);

  return (
    <div className="printer_view">
      <div className="title">Add Printer IP</div>
      <div className="cust_inp">
        <input
          type="text"
          placeholder="Insert Name"
          value={inp}
          onChange={({ target }) => {
            setinp(target.value);
            // setteam_data((prev) => ({ ...prev, name: target.value }));
          }}
        />
      </div>
      <div
        className="btn"
        type={inp === current_store.default?.printer_ip ? "type1" : "type2"}
        onClick={() => {
          if (inp === current_store.default?.printer_ip) {
            return;
          }
          setprinter_ip(inp);
          localStorage.setItem("jc_printer_ip", inp);
          dispatch({
            type: "put_default",
            payload: { printer_ip: inp },
          });
        }}
      >
        Save
      </div>
      {printer_ip && (
        <div
          className="btn"
          type="type2"
          onClick={() => {
            print_Data();
          }}
        >
          Test Printer
        </div>
      )}
      {/* <div className="title">Add Printer IP</div>
      <div className="cust_inp">
        <input
          type="text"
          placeholder="Insert Name"
          onChange={({ target }) => {
            // setteam_data((prev) => ({ ...prev, name: target.value }));
          }}
        />
      </div> */}
      {/* <div className="title">Add Printer Name</div> */}
      {/* <div className="title">* printer data is stored locally</div> */}
    </div>
  );
};
