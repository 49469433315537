import { Layout } from "./Layout/Layout";
import { Provider } from "react-redux";
import "./styles/color.scss";
import "./styles/font.scss";
import "./styles/common.scss";

import store from "./store/store";

export const navbar_status = {
  client: true,
  team: true,
  items: true,
  discount: true,
  orders: true,
  add_orders: true,
  view_orders: true,
  cash: true,
  faq: true,
  vendor: true,
  reports: true,
  settings: true,
};

function App() {
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
}

export default App;
