const user = {};

const Default = (state = user, { type, payload }) => {
  switch (type) {
    case "put_default":
      return {
        ...state,
        ...payload,
        // admin: false,
      };
    default:
      return state;
  }
};
export default Default;
